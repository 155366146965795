import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row >
          <Col xs={12} sm={6} md={6}>
            <div className="copyrights-col-left">
              <p>Copyright © 2019<a target="_blank" rel="noopener noreferrer" href="homanystem.com"> Homan System</a>. All Rights Reserved</p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <div className="copyrights-col-right">
              {/* //<Router> */}
                <Link to="/kebijakan-privasi"> Kebijakan Privasi</Link> /
                <Link to="/syarat-ketentuan"> Syarat dan Ketentuan</Link> 
              {/* </Router> */}
            </div>
          </Col>

        </Row>
      </Container>
    </div>
  );
}

export default Footer;