import React from 'react'
import '../home/home.css'
import { Container, Row, Col, Image } from 'react-bootstrap';


const Home = () => {
    return (
        <div>
            <div className="full-width-image">
                <img src="./image/image.jpg" alt="Logo" />
            </div>
            <Container>
                <Row>
                    <p className="deskripsi">Aplikasi Kasir Monkey POS: Software manajemen restoran, cafe, warung makan termudah! Dirancang untuk mempermudah proses bisnis Anda dari mendaftarkan menu, mencatat pesanan, pembayaran serta pelaporan.</p>
                </Row>
                <Row>
                    <Col xs={12} className="padding-bottom20">
                        <div className="border-bottom">
                            <h4>Tampilan Mobile</h4>
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Phone/AppMenu.png" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Phone/Hak Akses.png" className="img-fluid margin-5 center-block" alt="" thumbnail rounded />
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Phone/Laporan.png" className="img-fluid margin-5 center-block" alt="" thumbnail rounded />
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Phone/Login.png" className="img-fluid margin-5 center-block" alt="" thumbnail rounded />
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Phone/Menu.png" className="img-fluid margin-5 center-block" alt="" thumbnail rounded />
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Phone/NewOrder.png" className="img-fluid margin-5 center-block" alt="" thumbnail rounded />
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Phone/Order.png" className="img-fluid margin-5 center-block" alt=""  thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Phone/AppMenu.png" className="img-fluid margin-5 center-block" alt=""  thumbnail rounded/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="border-bottom">
                            <h4>Tampilan Tablet</h4>
                        </div>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Tablet_7/AppMenu.jpg" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Tablet_7/NewOrder.jpg" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Tablet_7/ListOrder.jpg" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Tablet_7/Login.jpg" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Tablet_7/HakAkses.jpg" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Tablet_7/Payment.jpg" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Tablet_7/Report.jpg" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    <Col xs={6} sm={4} md={3}>
                        <Image src="./image/SS_Tablet_7/VerticalMenu.jpg" className="img-fluid margin-5 center-block" alt="" thumbnail rounded/>
                    </Col>
                    
                </Row>
            </Container>
        </div>
    )
}
export default Home