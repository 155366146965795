import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './component/footer.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './component/header'
import Footer from './component/footer'
import { Route, NavLink, BrowserRouter as Router, Switch, Link } from 'react-router-dom'
import Notfound from './component/notfound'
import Home from './component/home/home'
import Aboutus from './component/aboutus/aboutus'
import Contactus from './component/contactus/contactus'
import Faq from './component/faq/faq'
import Feature from './component/feature/feature'
import tutorial from './component/tutorial/tutorial'
import SyaratKetentuan from './component/syarat-ketentuan/syarat-ketentuan'
import KebijkanPrivasi from './component/kebijakan-privasi/kebijakan-privasi'

const routing = (
    <div>
        <Router>
        <Header />

            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/feature" component={Feature} />
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/aboutus" component={Aboutus} />
                <Route exact path="/contactus" component={Contactus} />
                <Route exact path="/tutorial" component={tutorial} />
                <Route exact path="/sigin-sales" component={Home} />
                <Route path="/kebijakan-privasi" component={KebijkanPrivasi} />
                <Route path="/syarat-ketentuan" component={SyaratKetentuan} />
                <Route component={Notfound} />
            </Switch>
            <div style={{ 'padding': '30px' }}>&nbsp;</div>
        <Footer />
        </Router>
        
    </div>
)
ReactDOM.render(routing, document.getElementById('root'))

serviceWorker.unregister();
