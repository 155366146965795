import React, { useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import {  NavLink, Link } from 'react-router-dom'


const Header = () => {
    const [navExpanded, setNavExpanded] = useState(false)
    return (
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top" onToggle={setNavExpanded.bind(true)} expanded={navExpanded}>
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <img alt="logo" src="./image/logo.png" width="50" height="50" /> {'Monkey POS'}
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto" onClick={setNavExpanded.bind(this, false)}>
                            <NavLink className="nav-link" to="/">Home</NavLink>
                            <NavLink className="nav-link" to="/feature">Feature</NavLink>
                            <NavLink className="nav-link" to="/faq">Faq</NavLink>
                            <NavLink className="nav-link" to="/aboutus">About Us</NavLink>
                            <NavLink className="nav-link" to="/contactus">Contact Us</NavLink>
                            <NavLink className="nav-link" to="/tutorial">Tutorial</NavLink>
                        </Nav>
                        <Nav>
                            <NavLink className="nav-link" to="/sigin-sales">Sign in sales</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    )
}

export default Header