import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

const SyaratKetentuan = () => {
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="border-bottom pd-top10">
                        <h3>Syarat dan Ketentuan</h3>
                    </div>
                </Col>
                <Col xs={12}>
                    <p>
                        Dengan mengunduh atau menggunakan aplikasi, ketentuan ini akan berlaku secara otomatis untuk Anda - karena itu Anda harus memastikan bahwa Anda membacanya dengan cermat sebelum menggunakan aplikasi. Anda tidak diperbolehkan menyalin, atau memodifikasi aplikasi, bagian apa pun dari aplikasi, atau merek dagang kami dengan cara apa pun. Anda tidak diizinkan untuk mencoba mengekstrak kode sumber aplikasi, dan Anda juga tidak boleh mencoba menerjemahkan aplikasi ke bahasa lain, atau membuat versi turunan. Aplikasi itu sendiri, dan semua merek dagang, hak cipta, hak basis data dan hak kekayaan intelektual lainnya yang terkait, masih milik PT. Mega Homan Indonesia.
                </p>
                    <h6 className="color1">Hal Umum</h6>
                    <p>PT. Mega Homan Indonesia adalah perseroan yang didirikan berdasarkan hukum Negara Republik Indonesia.</p>
                    <p>Aplikasi Monkey POS merupakan aplikasi perangkat lunak yang berfungsi sebagai sarana bagi pengusaha bidang kulineri untuk:</p>
                    <ul>
                        <li>Menyinpan data produk</li>
                        <li>Mecatat orderan</li>
                        <li>Manajemen orderan</li>
                        <li>Perhitungan tagihan</li>
                        <li>Pelaporan & analisa</li>
                    </ul>
                    <h6 className="color1">Mengenai Perubahan pada aplikasi dan Biaya layanan</h6>
                    <p>
                        PT. Mega Homan Indonesia berkomitmen untuk memastikan bahwa aplikasi ini bermanfaat dan seefisien mungkin. Untuk alasan itu, kami berhak untuk membuat perubahan pada aplikasi atau membebankan biaya untuk layanannya, kapan saja dan untuk alasan apa pun. Kami tidak akan pernah menagih Anda untuk aplikasi atau layanannya tanpa membuatnya sangat jelas kepada Anda apa yang Anda bayar.
                </p>
                    <h6 className="color1">Data Pribadi</h6>
                    <p>
                        Aplikasi Monkey POS menyimpan dan memproses data pribadi yang telah Anda berikan kepada kami, untuk memberikan Layanan kami. Adalah tanggung jawab Anda untuk menjaga ponsel Anda dan akses ke aplikasi tetap aman. Karena itu kami menyarankan Anda untuk tidak melakukan jailbreak atau melakukan root pada ponsel Anda, yang merupakan proses menghilangkan batasan dan batasan perangkat lunak yang diberlakukan oleh sistem operasi resmi perangkat Anda. Itu bisa membuat ponsel Anda rentan terhadap malware / virus / program jahat, kompromi fitur keamanan ponsel Anda dan itu bisa berarti bahwa aplikasi Monkey POS tidak akan berfungsi dengan baik atau tidak sama sekali.
                </p>
                    <h6 className="color1">Tanggung Jawab</h6>
                    <p>Anda harus sadar bahwa ada hal-hal tertentu yang PT. Mega Homan Indonesia tidak akan bertanggung jawab atas. Fungsi-fungsi tertentu dari aplikasi akan membutuhkan aplikasi untuk memiliki koneksi internet aktif. Koneksi dapat berupa Wi-Fi, atau disediakan oleh penyedia jaringan seluler Anda, tetapi PT. Mega Homan Indonesia tidak dapat bertanggung jawab atas aplikasi yang tidak berfungsi pada fungsionalitas penuh jika Anda tidak memiliki akses ke Wi-Fi, dan Anda tidak memiliki sisa data yang tersisa.</p>
                    <p>Jika Anda menggunakan aplikasi di luar area dengan Wi-Fi, Anda harus ingat bahwa ketentuan perjanjian Anda dengan penyedia jaringan seluler Anda akan tetap berlaku. Akibatnya, Anda mungkin dikenai biaya oleh penyedia layanan seluler Anda untuk biaya data selama koneksi berlangsung saat mengakses aplikasi, atau biaya pihak ketiga lainnya. Dalam menggunakan aplikasi, Anda menerima tanggung jawab atas tagihan semacam itu, termasuk biaya data roaming jika Anda menggunakan aplikasi di luar wilayah rumah Anda (mis. Wilayah atau negara) tanpa mematikan roaming data. Jika Anda bukan pembayar tagihan untuk perangkat tempat Anda menggunakan aplikasi, harap perhatikan bahwa kami menganggap bahwa Anda telah menerima izin dari pembayar tagihan untuk menggunakan aplikasi tersebut.</p>
                    <p>Sejalan dengan itu, PT. Mega Homan Indonesia tidak dapat selalu bertanggung jawab atas cara Anda menggunakan aplikasi yaitu Anda perlu memastikan bahwa perangkat Anda tetap terisi daya - jika kehabisan baterai dan Anda tidak dapat menyalakannya untuk memanfaatkan Layanan, PT. Mega Homan Indonesia tidak dapat menerima tanggung jawab.</p>
                    <p>Sehubungan dengan Tanggung jawab PT. Mega Homan Indonesia atas penggunaan Anda terhadap aplikasi ini, ketika Anda menggunakan aplikasi tersebut, penting untuk diingat bahwa meskipun kami berupaya memastikan bahwa itu diperbarui dan diperbaiki setiap saat, kami mengandalkan pihak ketiga untuk memberikan informasi kepada kami sehingga kami dapat membuatnya tersedia untuk Anda. PT. Mega Homan Indonesia tidak bertanggung jawab atas kerugian, langsung atau tidak langsung, yang Anda alami sebagai akibat sepenuhnya mengandalkan fungsi aplikasi ini.</p>
                    <p>
                        Pada titik tertentu, kami mungkin ingin memperbarui aplikasi. Aplikasi saat ini tersedia di Android - persyaratan untuk sistem (dan untuk sistem tambahan apa pun yang kami putuskan untuk memperpanjang ketersediaan aplikasi) dapat berubah, dan Anda harus mengunduh pembaruan jika Anda ingin tetap menggunakan aplikasi. PT. Mega Homan Indonesia tidak berjanji bahwa ia akan selalu memperbarui aplikasi sehingga relevan bagi Anda dan / atau bekerja dengan versi Android yang telah Anda instal pada perangkat Anda. Namun, Anda berjanji untuk selalu menerima pembaruan aplikasi ketika ditawarkan kepada Anda, Kami mungkin juga ingin berhenti menyediakan aplikasi, dan dapat menghentikan penggunaannya setiap saat tanpa pemberitahuan pengakhiran kepada Anda. Kecuali kami memberi tahu Anda sebaliknya, pada setiap penghentian, (a) hak dan lisensi yang diberikan kepada Anda dalam ketentuan ini akan berakhir; (B) Anda harus berhenti menggunakan aplikasi, dan (jika perlu) menghapusnya dari perangkat Anda.
                </p>
                    <h6 className="color1">Masa Berlaku Layanan</h6>
                    <p>Pengunaan aplikasi ini bersifat langganan. Anda akan ditagih sejumlah biaya untuk penggunaan dalam durasi 30 hari.</p>
                    <p>Perpanjangan layanan dihitung berdasarkan tanggal kaduluarsa aplikasi terakhir saat melakukan pembayaran.</p>
                    <h6 className="color1">Pembayaran</h6>
                    <p>Pengunduhan aplikasi ini adalah bebas biaya. Kami dapat memperkenalkan pengenaan biaya untuk pengunduhan aplikasi di setiap saat. Kami akan memberitahu Anda tentang hal ini sebagaimana mestinya agar Anda dapat memutuskan apakah Anda ingin terus menggunakan aplikasi tersebut atau tidak. Namun demikian, koneksi internet yang dibutuhkan untuk menggunakan layanan dan setiap biaya terkait yang ditimbulkan oleh pengunaan layanan adalah tanggung jawab Anda.</p>
                    <p>Tarif yang berlaku untuk layanan kami bersifat langganan (subscription based). Periode langganan yang kami sediakan adalah per 30 hari. Kami dapat merubah skema periode langganan dan/atau  biaya langganan. Kami akan memberitahu Anda tentang hal ini sebagaimana mestinya agar Anda dapat memutuskan apakah Anda ingin terus menggunakan aplikasi tersebut atau tidak.</p>
                    <p>Dalam hal pembayaran, aplikasi Monkey POS menggunakan jasa Payment Gateway dari Midtrans sebagai pihak ketiga untuk mengelola metode pembayaran pada aplikasi. Anda diharapkan untuk membaca Kebijakan Privasi di <a target="_blank" rel="noopener noreferrer" href="https://midtrans.com/privacy-policy">https://midtrans.com/privacy-policy</a></p>
                    <p>Akun berbayar Anda akan tetap berlaku hingga masa berlaku Anda habis atau dihentikan berdasarkan Syarat dan Ketentuan yang berlaku. Jika Anda tidak membayar biaya perpanjangan, kami berhak untuk melakukan penangguhan atau penghapusan data Anda.</p>
                    <h6 className="color1">
                        Ketidakpuasan dan Pengembalian Dana
                </h6>
                    <p>Kami sangat senang untuk mendengarkan keluhan Anda selama menggunakan aplikasi kami. Anda dapat menyampaikan keluhan Anda dengan menghubungi kami melalui email di <a target="_blank" rel="noopener noreferrer" href="mailto:cs@homansystem.com">cs@homansystem.com</a> atau menggunjungi <a target="_blank" rel="noopener noreferrer" href="https://homansystem.com/contactus">https://homansystem.com/contactus</a> untuk menyampaikan keluhan Anda.</p>
                    <p>
                        Mohon maaf. Kami TIDAK menawarkan pengembalian dana untuk biaya langganan yang telah dibayarkan.
                </p>
                    <h6 className="color1">Pemutusan Kontrak</h6>
                    <p>Anda berhak untuk berhenti menggunakan layanan kami sewaktu-waktu tanpa pemberitahuan.</p>
                    <p>Kami berhak untuk melakukan penanggalan atau pemutusan kontrak sepihak jika kami menemukan:</p>
                    <ul>
                        <li>
                            Anda melanggar Syarat dan Ketentuan yang berlaku.
                    </li>
                        <li>Penggunaan Anda atas layanan dengan cara yang menyebabkan resiko nyata kerugian bagi kami atau pengguna lain.</li>
                        <li>Anda tidak melakukan perpanjangan masa langganan Anda untuk 4 bulan berturut-turut.</li>
                    </ul>
                    <h6 className="color1">Pemberhentian Layanan</h6>
                    <p>Kami dapat memutuskan untuk memberhentikan layanan sebagai tanggapan terhadap keadaan yang tidak terduga di luar kendali kami atau untuk mematuhi persyaratan hukum. Jika kami melakukannya, kami akan memberi Anda pemberitahuan sebelumnya yang masuk akal. Jika kami menghentikan layanan dengan cara ini sebelum akhir dari jangka waktu langganan Anda, kami akan mengembalikan sebagian biaya yang telah Anda bayar di muka tetapi belum menerima layanan kami.</p>
                    <h6 className="color1">Perubahan pada Syarat dan Ketentuan ini</h6>
                    <p>Kami dapat memperbarui Syarat dan Ketentuan kami dari waktu ke waktu. Dengan demikian, Anda disarankan untuk meninjau halaman ini secara berkala untuk setiap perubahan. Kami akan memberi tahu Anda tentang segala perubahan dengan memposting Syarat dan Ketentuan baru di halaman ini. Perubahan ini efektif segera setelah diposting di halaman ini.</p>
                    <h6 className="color1">Hubungi kami</h6>
                    <p>Jika Anda memiliki pertanyaan atau saran tentang Syarat dan Ketentuan kami, jangan ragu untuk menghubungi kami di <a target="_blank" rel="noopener noreferrer" href="mailto:cs@homansystem.com">cs@homansystem.com</a>.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default SyaratKetentuan