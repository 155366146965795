import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

const Feature = () => {
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <div className="border-bottom pd-top10">
                        <h3>Fitur Monkey POS</h3>
                    </div>
                </Col>
                <div>&nbsp;</div>
                <Col xs={12}>
                    <h5> Mudah</h5>
                    <p>Staff Anda akan mudah beradaptasi dengan aplikasi Monkey POS karena interfacenya yang sangat ‘straightforward’ dan mudah dimengerti.</p>
                    <h5>All in One</h5>
                    <p>Semua dapat dilakukan di satu tempat. Satu aplikasi untuk semua. Mulai dari manajemen data, manajemen orderan, sampai ke manajemen stok dilakukan di smart devices Anda. Oh ya, kedepan kita juga akan mengcover perhitungan akunting Anda.</p>
                    <h5>Captain Order</h5>
                    <p>Pelanggan Anda tidak akan resah lagi dengan salah pesanan ataupun pesanan yang tidak kunjung diantar. Pelayan Anda dapat dengan mudah mengantarkan pesanan ke tempat yang dituju melalui Captain Order</p>
                    <h5>Split Bill a.k.a BMM / Bayar Masing-Masing</h5>
                    <p>Bayar masing-masing sudah menjadi tren di kalangan anak zaman now. Pelanggan Anda akan senang dengan waktu yang dihematkan dari perhitungan dan pencetakan tagihan secara terpisah. Tidak perlu ribut dengan uang kecil lagi.</p>
                    <h5>Multi Payment</h5>
                    <p>Saldo ATM tidak cukup? Atau Cash kurang? Tenang saja! Monkey POS ini sudah ada fitur Multi Payment loh. Bayar saja dengan cash, kekuranganya dengan saldo ATM. Beres deh.</p>
                    <h5>Digital Bill / Printed Bill</h5>
                    <p>Anda diberikan pilihan untuk memilih jenis tagihan. Dapat berbentuk digital yang dikirimkan melalui E-mail dengan PDF atau dengan dicetak melalui POS Printer. Oh ya, kita mendukung Bluetooth POS Printer ataupun Printer POS Epson TM-T82 versi Ethernet.</p>
                    <h5>Hak Akses</h5>
                    <p>Batasi hak penggunaan staff berdasarkan job desk yang diberikan. Anda tidak perlu khawatir tentang penyalahgunaan akses dari staff Anda.</p>
                    <h5>Offline First</h5>
                    <p>Aplikasi kami dirancang dengan konsep “Offline First” yang mengutamakan pengalaman saat Anda tidak terhubung pada koneksi Internet. Berbeda dengan aplikasi lainnya. Pada umumnya, aplikasi lainnya menggunakan konsep Online First dimana data online yang diutamakan dan data offline untuk ketika terjadi gangguan internet. Semua fitur POS dapat dinikmati oleh pengguna baik dalam kondisi offline maupun online.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Feature